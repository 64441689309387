import React from 'react'
import Layout from '../components/layouts/layout'
import desktopBackgroundImage from '../images/banner-kendra-desktop.jpg'
import mobileBackgroundImage from '../images/banner-kendra-mobile.jpg'
import ContactBlock from '../components/blocs/contact'
import { Colors } from '../colors'
import { Hero } from '../components/hero/hero'
import { useLayoutInformation } from '../hooks/useLayoutInformation'

export const Contact = () => {
    const { isMobile, secondaryColor } = useLayoutInformation(Colors.orange)

    return (
        <Layout backgroundColor={Colors.lightBlue} titleIntlId="Contact">
            <Hero
                backgroundImageUri={isMobile ? mobileBackgroundImage : desktopBackgroundImage}
                message="Besoin de prendre des décisions financières éclairées?"
                buttonColor={secondaryColor}
            />
            <ContactBlock overlapPreviousElement />
        </Layout>
    )
}

export default Contact
